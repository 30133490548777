.order-detail__container {
  width: 100%;
  .order-detail__description {
    width: 70%;
  }
  .order-detail__map{
    width: 400px;
    height: 300px;
  }
}
