.orders__container {
  height: 100%;
  width: 100%;

  .orders__card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > * {
      margin-top: 2rem;
    }
  }
}
