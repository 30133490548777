.time-preferences__container {
  ul.time-preferences__events {
    margin: 0;
    padding: 0;
    list-style: 'none';
    > li {
      .ant-badge-status-text {
        font-size: 8pt;
        text-overflow: ellipsis;
      }
      .ant-tag {
        width: 100%;
        font-size: 8pt;
      }
    }
  }
}
