// @import '~antd/dist/antd.dark.less';
@import '~antd/dist/antd.less';

.app__wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .app__header {
    height: 7vh;
  }

  .app__container {
    flex: 1 1 auto;
    // background-color: #1a1a1a;
    display: flex;
    flex-direction: row;
    height: 93vh;

    .app__side-menu {
      flex: 0 0 200px;
      display: flex;
    }

    .app__content {
      padding: 1rem 1rem 5rem 1rem;
      width: 100%;
      //flex: 1 1 auto;
      //display: flex;
      overflow-y: auto;
    }
  }
}

@primary-color: #e8912a;